<template>
  <div>
    <CyNotification
      v-if="!isCycloidEmployee"
      type="info"
      :title="$t('notification.title')"
      :content="$t('notification.content')"/>

    <CyDataTableYdApi
      id="cy-roles-table"
      ref="cyDataTable"
      :bulk="hasBulkModeEnabled"
      :fetch-available="{ keyPath: 'roles' }"
      :headers="$static.headers"
      :link-builder="getLinkTarget"
      :searchable-fields="$static.searchableFields"
      key-field="canonical">
      <template #table-cmp-header-actions="{ selected }">
        <!-- Delete modal -->
        <CyModal
          v-if="showDeleteModal"
          :header-title="_.capitalize($tc('deleteRoles', toDelete.length, { nb: toDelete.length }))"
          :action-btn-func="onDelete"
          :cancel-btn-func="() => $toggle.showDeleteModal(false)"
          :action-btn-text="_.capitalize($tc('deleteRoles', toDelete.length, { nb: toDelete.length }))"
          :loading="isDeleting"
          small
          modal-type="delete">
          <div
            v-for="{ canonical, name } of toDelete"
            :key="canonical">
            <h3>{{ name }}</h3>
          </div>
          <p class="mt-2">
            {{ $t('forms.cannotBeUndone') }}
          </p>
        </CyModal>

        <CyButton
          v-if="!_.isEmpty(selected)"
          theme="error"
          icon="delete"
          @click="openDeleteModal(selected)">
          {{ _.capitalize($tc('deleteRoles', selected.length, { nb: selected.length })) }}
        </CyButton>

        <CyButton
          v-else-if="isCycloidEmployee"
          v-has-rights-to="'CreateRole'"
          icon="add"
          :to="{ name: 'newRole' }">
          {{ _.capitalize($t('addRole')) }}
        </CyButton>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td v-has-rights-to="['GetRole', item.canonical]">
          <span>{{ item.name }}</span>
          <CyTag
            v-if="item.default"
            variant="primary"
            small
            class="ml-2">
            {{ _.toLower($t('untranslated.default')) }}
          </CyTag>
        </td>

        <td>{{ _.truncate(item.description, { length: 80 }) }}</td>

        <td class="menu-cell">
          <CyMenu
            v-if="!_.$isEmpty(getRoleActions(item))"
            :items="getRoleActions(item)"/>
        </td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { constructBreadcrumb } from '@/utils/helpers'
import { mapState, mapActions, mapGetters } from 'vuex'
import CyDataTableYdApi from '@/components/data-table-yd-api'
import CyTag from '@/components/tag'

export default {
  name: 'CyPageRoles',
  components: {
    CyDataTableYdApi,
    CyTag,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.roles'), [
      {
        label: this.$t('routes.securitySection'),
        name: 'securitySection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.securitySection'),
      description: {
        text: this.$t('routes.securitySectionDescription'),
      },
    }
  },
  data: () => ({
    showDeleteModal: false,
    toDelete: [],
    isDeleting: false,
  }),
  computed: {
    ...mapState('organization', {
      roles: (state) => state.available.roles,
    }),
    ...mapGetters('user', ['isCycloidEmployee']),
    $static () {
      return {
        headers: [
          {
            text: this.$t('roleName'),
            value: 'name',
            align: 'left',
          },
          {
            text: this.$t('forms.fieldDescription'),
            value: 'description',
            align: 'left',
          },
          {
            text: '',
            value: 'action',
            align: 'center',
            sortable: false,
          },
        ],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('roleName'),
          },
          {
            name: 'description',
            label: this.$t('forms.fieldDescription'),
          },
        ],
      }
    },
    hasBulkModeEnabled () {
      return _.every([
        !_.isEmpty(this.roles),
        this.$cycloid.permissions.canDisplay('DeleteRole'),
      ])
    },
  },
  methods: {
    ...mapActions('organization', [
      'BULK_DELETE',
    ]),
    cloneRole ({ name, description, rules }) {
      this.$router.push({
        name: 'newRole',
        params: {
          duplicate: {
            name: `${name} (copy)`,
            description,
            rules,
          },
        },
      })
    },
    getLinkTarget ({ canonical: roleCanonical } = {}) {
      return {
        name: 'role',
        params: { roleCanonical },
      }
    },
    openDeleteModal (toDelete) {
      this.toDelete = toDelete
      this.$toggle.showDeleteModal(true)
    },
    async onDelete () {
      const { toDelete } = this
      this.$toggle.isDeleting(true)
      await this.BULK_DELETE({ keyPath: 'roles', toDelete })
      this.$toggle.isDeleting(false)
      this.$toggle.showDeleteModal(false)
      this.toDelete = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
    getRoleActions (role) {
      const { canDisplay } = this.$cycloid.permissions
      const { canonical: roleCanonical, default: isDefaultRole } = role

      const actions = [
        {
          label: this.$t('clone'),
          icon: 'add_to_photos',
          action: () => this.cloneRole(role),
          permissionKey: 'CreateRole',
        },
        ...(!isDefaultRole
          ? [{
              label: this.$t('forms.btnDelete'),
              icon: 'delete',
              action: () => this.openDeleteModal([role]),
              permissionKey: 'DeleteRole',
            }]
          : []
        ),
      ]

      return actions.filter(({ permissionKey }) => canDisplay(permissionKey, roleCanonical))
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:Roles list',
        addRole: 'Add @:Role',
        deleteRoles: 'Delete @:Role | Delete {nb} @:Roles',
        roleName: '@:Role name',
        notification: {
          title: 'Assign roles to members and teams to give access to predefined features.',
          content: 'For more granularity, clone roles to give access to specific resources only. Custom roles are only available by contacting our technical team.',
        },
      },
      es: {
        title: 'Lista de @:Roles',
        addRole: 'Añadir un @:Role',
        deleteRoles: 'Eliminar @:Role | Eliminar {nb} @:Roles',
        roleName: 'Nombre del @:Role',
        notification: {
          title: 'Asigne roles a miembros y equipos para dar acceso a funciones predefinidas.',
          content: 'Para mayor granularidad, clone roles para dar acceso solo a recursos específicos. Roles personalizados se podrán obtener poniéndose en contacto con nuestro equipo técnico.',
        },
      },
      fr: {
        title: 'Liste de @:Roles',
        addRole: 'Ajouter un @:Role',
        deleteRoles: 'Supprimer @:Role | Supprimer {nb} @:Roles',
        roleName: 'Nom du @:Role',
        notification: {
          title: 'Attribuez des rôles aux membres et aux équipes pour donner accès à des fonctionnalités prédéfinies.',
          content: `Pour plus de granularité, clonez les rôles pour donner accès à des ressources spécifiques uniquement. Les rôles personnalisés ne sont disponibles qu'en contactant notre équipe technique.`,
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-cell ::v-deep .router-link-wrapper {
  justify-content: end;
}
</style>
