var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isCycloidEmployee)?_c('CyNotification',{attrs:{"type":"info","title":_vm.$t('notification.title'),"content":_vm.$t('notification.content')}}):_vm._e(),_c('CyDataTableYdApi',{ref:"cyDataTable",attrs:{"id":"cy-roles-table","bulk":_vm.hasBulkModeEnabled,"fetch-available":{ keyPath: 'roles' },"headers":_vm.$static.headers,"link-builder":_vm.getLinkTarget,"searchable-fields":_vm.$static.searchableFields,"key-field":"canonical"},scopedSlots:_vm._u([{key:"table-cmp-header-actions",fn:function(ref){
var selected = ref.selected;
return [(_vm.showDeleteModal)?_c('CyModal',{attrs:{"header-title":_vm._.capitalize(_vm.$tc('deleteRoles', _vm.toDelete.length, { nb: _vm.toDelete.length })),"action-btn-func":_vm.onDelete,"cancel-btn-func":function () { return _vm.$toggle.showDeleteModal(false); },"action-btn-text":_vm._.capitalize(_vm.$tc('deleteRoles', _vm.toDelete.length, { nb: _vm.toDelete.length })),"loading":_vm.isDeleting,"small":"","modal-type":"delete"}},[_vm._l((_vm.toDelete),function(ref){
var canonical = ref.canonical;
var name = ref.name;
return _c('div',{key:canonical},[_c('h3',[_vm._v(_vm._s(name))])])}),_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('forms.cannotBeUndone'))+" ")])],2):_vm._e(),(!_vm._.isEmpty(selected))?_c('CyButton',{attrs:{"theme":"error","icon":"delete"},on:{"click":function($event){return _vm.openDeleteModal(selected)}}},[_vm._v(" "+_vm._s(_vm._.capitalize(_vm.$tc('deleteRoles', selected.length, { nb: selected.length })))+" ")]):(_vm.isCycloidEmployee)?_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateRole'),expression:"'CreateRole'"}],attrs:{"icon":"add","to":{ name: 'newRole' }}},[_vm._v(" "+_vm._s(_vm._.capitalize(_vm.$t('addRole')))+" ")]):_vm._e()]}},{key:"table-cmp-body-row",fn:function(ref){
var item = ref.props.item;
return [_c('td',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:(['GetRole', item.canonical]),expression:"['GetRole', item.canonical]"}]},[_c('span',[_vm._v(_vm._s(item.name))]),(item.default)?_c('CyTag',{staticClass:"ml-2",attrs:{"variant":"primary","small":""}},[_vm._v(" "+_vm._s(_vm._.toLower(_vm.$t('untranslated.default')))+" ")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(_vm._.truncate(item.description, { length: 80 })))]),_c('td',{staticClass:"menu-cell"},[(!_vm._.$isEmpty(_vm.getRoleActions(item)))?_c('CyMenu',{attrs:{"items":_vm.getRoleActions(item)}}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }